import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import AboutPage from './components/AboutPage.vue';
import StatusPage from './components/StatusPage.vue';
import Settings from './components/SettingsPage.vue';
import Navigation from './components/NavigationPage.vue';
import Play from './components/PlayPage.vue';
import Confirmation from './components/ConfirmationPage.vue';
import Leaderboard from './components/LeaderboardPage.vue';
import PrkenLand from './components/PrkenLandPage.vue';
import EditAvatar from './components/EditAvatarPage.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage
    },
    {
      path: '/play',
      name: 'Play',
      component: Play
    },
    {
      path: '/about',
      name: 'About',
      component: AboutPage
    },
    {
      path: '/status',
      name: 'Status',
      component: StatusPage
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings
    },
    {
      path: '/navigation',
      name: 'Navigation',
      component: Navigation
    },
    {
      path: '/confirmation',
      name: 'Confirmation',
      component: Confirmation
    },
    {
      path: '/leaderboard',
      name: 'Leaderboard',
      component: Leaderboard
    },
    {
      path: '/prkenland',
      name: 'Prkenland',
      component: PrkenLand
    },
    {
      path: '/edit-avatar',
      name: 'EditAvatar',
      component: EditAvatar
    }
  ]
});

export default router;
