const avatars = {
    flying_emily: {
        name: "Flying Emily",
        img: require('./flying_emily.png')
    },
    handy_henk: {
        name: "Handy Henk",
        img: require('./handy_henk.png')
    },
    himher_lunette: {
        name: "Himher Lunette",
        img: require('./himher_lunette.png')
    },
    jimmy_lay_low: {
        name: "Jimmy Lay Low",
        img: require('./jimmy_lay_low.png')
    },
    misty_the_unicorn: {
        name: "Misty the Unicorn",
        img: require('./misty_the_unicorn.png')
    },
    peter_knows_it_all: {
        name: "Peter Knows It All",
        img: require('./peter_knows_it_all.png')
    },
    smart_alony: {
        name: "Smart Alony",
        img: require('./smart_alony.png')
    },
    speedy_tom: {
        name: "Speedy Tom",
        img: require('./speedy_tom.png')
    },
    spoofy_goofy: {
        name: "Spoofy Goofy",
        img: require('./spoofy_goofy.png')
    },
    winky_tina: {
        name: "Winky Tina",
        img: require('./winky_tina.png')
    },
    witful_betty: {
        name: "Witful Betty",
        img: require('./flying_emily.png')
    }
};

module.exports = {
    avatars,
};