<template>
  <div id="mapPlayPage"></div>
  <div id="targeter">
    <p v-if="!timerRunning" class="spacer-text">Tell us - is the parking slot free or taken?</p>
    <p v-if="timerRunning" class="spacer-text">Hint: it takes 2 minutes until you are able to earn your next 10 prken. coins.</p>
    <p v-if="timerRunning" class="spacer-text">Time Elapsed: {{ remainingSeconds }} seconds</p>
    <div v-if="!timerRunning">
      <button class="targeter-button free" @click="fetchGPSLocation(true)" :disabled="isLoading">Free</button>
      <button class="targeter-button taken" @click="fetchGPSLocation(false)" :disabled="isLoading">Taken</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Leaflet icon configuration
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Axios configuration
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
</script>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

// Reactive references and store/router setup
const isLoading = ref(false);
const map = ref(null);
const router = useRouter();
const store = useStore();

// Fetch GPS location
const fetchGPSLocation = (isFree) => {
  if ('geolocation' in navigator) {
    isLoading.value = true;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        sendParkingSpotStatusToServer(isFree, position.coords.latitude, position.coords.longitude);
        // store.dispatch('startTimer');
      },
      (error) => {
        console.error(error);
        isLoading.value = false;
        alert('Error getting location: ' + error.message);
      }
    );
  } else {
    alert('Geolocation is not supported by this browser.');
  }
};

// Send parking spot status to the server
const sendParkingSpotStatusToServer = async (isFree, latitude, longitude) => {
  isLoading.value = true;
  console.log(`Updating status to: ${isFree ? 'Free' : 'Occupied'}`);
  console.log(`GPS Location: ${latitude}, ${longitude}`);

  const payload = {
    geometry: {
      type: "Point",
      coordinates: [longitude, latitude],
    },
    timestamp: new Date().toISOString(),
    observationValue: isFree ? 1 : 0,
  };

  try {
    const response = await axios.post('/api/parking-spots', payload);
    console.log(response.data);
    router.push('/confirmation');
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

// Lifecycle hook for mounting the Leaflet map
onMounted(() => {
  map.value = L.map('mapPlayPage', {
    zoomControl: false,
    center: [51.505, -0.09],
    zoom: 13,
  });

  L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
  }).addTo(map.value);

  const onLocationFound = (e) => {
    const radius = e.accuracy;
    L.marker(e.latlng).addTo(map.value);
    L.circle(e.latlng, radius).addTo(map.value);
  };

  map.value.on('locationfound', onLocationFound);
  map.value.locate({ watch: false, setView: true, maxZoom: 17 });

  store.dispatch('fetchLastObservationTime');

  if (store.state.timerRunning) {
    isLoading.value = true;
  }
});

// Computed properties for reactivity with Vuex store
const remainingSeconds = computed(() => store.getters.remainingSeconds);
const timerRunning = computed(() => store.getters.timerRunning);

watch(
  () => store.getters.timerRunning,
  (newValue) => {
    isLoading.value = newValue;
  }
);
</script>

<style>
#mapPlayPage { 
  position: relative;
  top: 20px;
  z-index: 1;
  height: 89vh;
  width: 320px;
  border-radius: 25px;
  margin: 0 auto;
}
#targeter {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0px auto;
  background-color: #FEF8F4;
  border-radius: 10px;
  box-shadow: 3px 3px 5px gray;
  height: 149px;
  position: absolute;
  z-index: 2;
  width: 270px;
  top: calc(75% - 75px);
  left: calc(50% - 135px);
}

.targeter-img {
  width: 100%;
  height: auto;
  margin: 30px auto;
}

.spacer-text {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.targeter-button {
  color: white;
  border: 0px solid #333333;
  border-radius: 30px;
  padding: 10px;
  margin: 10px;
  width: 100px;
  font-weight: 500;
  box-shadow: 2px 2px 5px wheat;
  font-size: 23px;
}

.free {
  background-color: #98ffc3;
}

.taken {
  background-color: #ff6086;
}

button:disabled{
  background-color: #cccccc;
  color: #666666;
}
</style>
