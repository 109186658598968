<template>
  <div class="content-container">
    <div v-if="!selectedAvatar" class="avatar-container">
      <p>Loading...</p>
    </div>
    <div v-else class="avatar-container">
      <img class="selected-avatar-icon" :src="avatars[selectedAvatar].img" alt="Selected Avatar" />
      <p>Hi, {{ avatars[selectedAvatar].name }}!</p>
    </div>
    <router-link class="button" to="/edit-avatar">Edit Avatar</router-link>
    <a class="button" @click="logout">Logout</a>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { avatars } from '../avatars/avatars';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

const selectedAvatar = ref(null);
const store = useStore();
const user = ref({
  avatar: '',
  userMetadata: {
    email: '',
    picture: ''
  }
});

const logout = () => {
  store.dispatch('logout');
};

onMounted(async () => {
  const response = await axios.get('/api/query-user-data');
  user.value = response.data[0];
  selectedAvatar.value = user.value.avatar;
  console.log(user.value);
});
</script>

<script>
export default {
  name: 'SettingsPage',
};
</script>

<style scoped>
.avatar-container {
  text-align: center;
  margin-bottom: 50px;
}

.selected-avatar-icon {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
  display: block;
}

.button {
  display: block;
  width: 270px;
  padding: 5px;
  margin: 0px auto;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #ffffff;
  outline: 1px solid #ccc;
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: 500;
  cursor: pointer;
}
</style>