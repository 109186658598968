<template>
  <div class="content-container">
    <h3>Select your Avatar:</h3>
    <div class="avatar-grid">
      <div v-for="(avatar, key) in avatars" :key="key" :class="['avatar']"
        @click="selectAvatar(key)">
        <img :src="avatar.img" :alt="avatar.name" />
        <p>{{ avatar.name }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { avatars } from '../avatars/avatars';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

const user = ref({
  avatar: '',
  userMetadata: {
    email: '',
    picture: ''
  }
});
const router = useRouter();

// Reactive reference to store selected avatar key
const selectedAvatar = ref(null);

// Method to select an avatar
const selectAvatar = (key) => {
  console.log('Selecting avatar', key);
  selectedAvatar.value = key;
  user.value.avatar = key;
  saveUserData();
};

const saveUserData = async () => {
  console.log('Saving user data');
  console.log(user.value);
  const response = await axios.post('/api/update-user-data', user.value);
  console.log(response);
  router.push('/settings');
};

onMounted(async () => {
  const response = await axios.get('/api/query-user-data');
  user.value = response.data[0];
  console.log(user.value);
});
</script>

<script>
export default {
  name: 'EditAvatarPage',
};
</script>

<style scoped>
h3 {
  text-align: left;
  margin-bottom: 30px;
}
.avatar-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 0px;
}

.avatar {
  margin: 0px;
  text-align: center;
  padding: 0px;
  cursor: pointer;
}

.avatar img {
  width: 75px;
  height: 75px;
}

.avatar p {
  margin: 0px;
  font-size: 14px;
}
</style>